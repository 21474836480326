.social-bar {
    width: auto;
    height: auto;
    text-align: center;
    border: 2px solid #3faee1;
    border-radius: 4px;
    position: absolute;
    right: 8px;
    background-color: white;
    padding: 8px 8px;
}

@media only screen and (max-width: 785px) {
    .social-bar {
        right: 5px;
        padding: 4px 4px;
    }
}

.social-bar-en {
    font-family: Lato, sans-serif;
    font-size: 14px;
}

.social-bar-th {
    font-family: Prompt, sans-serif;
    font-size: 14px;
}

div.display-large-screen {
    display: none;
}

@media only screen and (min-width: 785px) {
    div.display-large-screen {
        display: block;
    }
}

a.icon-link {
    padding: 0 4px;
    font-size: 20px;
}

a.icon-link#facebook {
    color: #3b5998;
}

a.icon-link#instagram {
    color: #000000;
}

a.icon-link#line {
    color: #00b900;
}

a.icon-link:hover {
    color: gray !important;
}
