.calendar-today {
    background-color: darkgrey;
    border-radius: 5px;
}

.calendar-duration {
    background-color: #89cff0;
    border-radius: 5px;
}


.calendar-selected-date {
/*.react-datepicker__day--selected {*/
    background-color: #00bbbb;
    border-radius: 5px;
}

.calendar-selected-fixed-date {
    background-color: #1155aa;
    border-radius: 5px;
}

.date-label {
    width: 140px;
    text-align: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: bold;
    color: #777777;
}

.date-input-field {
    background: white url("/booking-icon.png") no-repeat right;
    border-radius: 3px;
    height: 40px;
    width: 140px;
    text-align: left;
    padding-left: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    border: solid 0;
    font-family: Lato, sans-serif;
    color: #858585;
    font-size: 14px;
}

.date-input-field-reservation-form {
    height: 40px;
    width: 160px;
    margin: 0 10px;
    border: solid 1px #bbbbbb;
}

.date-input-field-reservation-form-invalid {
    height: 40px;
    width: 160px;
    margin: 0 10px;
    border: solid 1px #ff0000;
}

.date-input-field[disabled] {
    background: #bbbbbb url("/booking-icon.png") no-repeat right;
}

@media all and (min-width:768px) {
    .date-input-field {
        margin-left: 10px;
    }
}