.booking-bar {
    background-color: #89cff0;
    width: 100%;
    height: auto;
    padding: 10px;
}

.toggle-button {
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    font-weight: bold !important;
}

.booking-message {
    width: 100%;
    margin-top: 10px;
}

.booking-message span {
    width: 100%;
    text-align: center;
    color: gray;
}

@media only screen and (max-width: 992px) {
    .booking-message {
        display: none;
    }
}

.booking-date {
    margin: 0 5px;
}

@media only screen and (max-width: 360px) {
    .booking-date {
        width: 125px !important;
        font-size: 13px !important;
    }
}

button.submit-booking {
    height: 40px;
    margin: 30px 0 0 0;
    display: table-cell;
}

@media only screen and (max-width: 768px) {
    button.submit-booking {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        width: 80%;
    }
}

div.booking-phone {
    background-color: white;
    vertical-align: middle;
    margin: 0 0;
    padding: 10px;
    border-radius: 4px;
}

div.booking-phone a {
    white-space: nowrap;
}

@media only screen and (max-width: 576px) {
    div.booking-phone {
        margin-top: 10px;
    }
}

div.booking-phone-wrapper {
    margin: 0 0 0 0;
    text-align: center;
}

div.booking-phone-wrapper label {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 150px;
    color: #777777;
}

label#phone-button-label {
    display: block;
}

@media only screen and (max-width: 768px) {
    label#phone-button-label {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    div.booking-phone-wrapper {
        margin: 20px 0 5px 0;
    }
}