.main-panel {
    background-color: #f7f7f7;
    padding: 0 0 20px 0;
}

div.carousel-panel {
    width: 100%;
    height: auto;
}

.top-carousel {
    padding-top: 10px;
    text-align: center;
}

img.carousel-display {
    width: 100%;
}

.page-header-panel {
    margin-bottom: 30px;
    margin-top: 20px;
}

.content-key-header {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 15px;
}

.content-header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

a.content-link {
    color: #008ECC;
}

a.content-link:hover {
    color: #3ac1e1;
    text-decoration: none;
}

div.main-section {
    color: black;
}

div.main-section p {
    text-align: left;
}

div.main-section-en p {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

div.main-section-th p {
    font-family: PromptLight, sans-serif;
    font-size: 16px;
}

div.main-section-en span {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

div.main-section-th span {
    font-family: PromptLight, sans-serif;
    font-size: 16px;
}

div.main-section-en .action-button {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

div.main-section-th .action-button {
    font-family: Prompt, sans-serif;
    font-size: 14px;
}


div.main-section-en table {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

div.main-section-th table {
    font-family: PromptLight, sans-serif;
    font-size: 16px;
}

div.main-section h1 {
    color: #3ac1e1;
}

div.main-section-en h1 {
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 36px;
    font-weight: 400;
}

div.main-section-th h1 {
    font-family: Prompt, sans-serif;
    font-size: 36px;
    font-weight: 100;
}

div.main-section h2 {
    color: #23AFCE;
    /*color: #3ac1e1;*/
}

div.main-section-en h2 {
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 400;
}

div.main-section-th h2 {
    text-transform: uppercase;
    font-family: Prompt, sans-serif;
    font-size: 24px;
    font-weight: 100;
}

.item-display-title {
    text-align: left;
    padding-bottom: 10px;
}

div.main-section h3 {
    color: #085394;
}

div.main-section-en h3 {
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: 100;
}

div.main-section-th h3 {
    font-family: Prompt, sans-serif;
    font-size: 20px;
    font-weight: 100;
}

div.main-section-en button {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

div.main-section-th button {
    font-family: Prompt, sans-serif;
    font-size: 14px;
}

span.label {
    text-align: left;
    color: #777777;
    font-family: Lato, sans-serif;
    font-size: 16px;
}

img.tile-display {
    width: 100%;
}

.padded-tile {
    padding: 10px;
}

table.left-aligned {
    text-align: left;
}

table.center {
    margin-left: auto;
    margin-right: auto;
}

.label-text {
    padding-top: 15px;
    text-align: center;
    color: #777777;
}

.colored-number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #3ac1e1;
    border-radius: 100px;
    color: white;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
}

.grecaptcha-badge {
    visibility: hidden;
}

.action-button {
    background-color: white;
    padding: 0 30px;
    border: white;
    color: black;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    text-transform: uppercase;
    height: 50px;
}

.action-button:hover {
    color: #858585;
}

.button-link {
    padding: 13px 20px;
    margin: 5px 5px;
    white-space: nowrap;
}

.button-link:hover {
    color: #858585;
    text-decoration: none;
}

img.hover-darken:hover {
    opacity: 0.7;
}

.btn-primary {
    /*background-color: #F1BB87 !important;*/
    background-color: darkorange !important;
    /*background-color: #3ac1e1 !important;*/
    /*border: 2px solid #0f6674 !important;*/
    border: 2px solid white !important;
    border-radius: 6px !important;
    /*color: #1d5d90 !important;*/
    color: white !important;
    /*font-family: Lato, sans-serif !important;*/
    /*font-weight: bold !important;*/
}

.booking-bar-en {
    font-family: Lato, sans-serif;
    font-size: 14px;
}

.booking-bar-th {
    font-family: Prompt, sans-serif;
    font-size: 14px;
}

.booking-bar-en .date-input-field {
    font-family: Lato, sans-serif;
    font-size: 14px;
}

.booking-bar-th .date-input-field {
    font-family: Prompt, sans-serif;
    font-size: 14px;
}

.no-content-notice {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.no-content-notice h1 {
    color: #959595 !important;
    font-weight: bold !important;
    font-size: 40px !important;
    margin-bottom: 50px;
}

.no-content-notice h2 {
    color: #959595 !important;
}

.footer-bar {
    width: 100%;
    height: 100%;
    padding: 15px 15px;
    background-color: #89cff0;
    text-align: left;
}

.footer-bar span {
    color: #404E4D;
}

.footer-bar small {
    color: #404E4D;
}

.footer-bar a {
    color: white;
}
