body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'Prompt';
    src: local("Prompt"), url("./assets/fonts/prompt/Prompt-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'PromptLight';
    src: url("./assets/fonts/prompt/Prompt-Light.ttf");
    font-weight: 100;
}

/*@font-face {*/
    /*font-family: 'PromptItalic';*/
    /*src: local("Prompt"), url("./assets/fonts/prompt/Prompt-Italic.ttf") format("truetype");*/
    /*font-style: italic;*/
/*}*/