@media only screen and (max-width: 320px) {
    .navbar-logo {
        width: auto;
        height: 40px;
    }
}

@media only screen and (min-width: 320px) {
    .navbar-logo {
        width: auto;
        height: 40px;
    }
}

@media only screen and (min-width:768px) {
    .navbar-logo {
        width: auto;
        height: 50px;
    }
}

.nav-link-text {
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 13px;
    color: black;
}

.nav-link-text-th {
    font-family: Prompt, sans-serif;
    font-size: 16px;
    color: black;
}

a.nav-link.dropdown-item.active {
    background-color: white;
}

a.nav-link.dropdown-item:active {
    background-color: white;
}

a.nav-link:hover .nav-link-text {
    color: #89cff0;
}

a.nav-link.active .nav-link-text {
    color: #89cff0;
}

a.nav-link:hover .nav-link-text-th {
    color: #89cff0;
}

a.nav-link.active .nav-link-text-th {
    color: #89cff0;
}

.nav-dropdown {
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 13px;
    margin-top: 4px;
}

.nav-dropdown a.dropdown-toggle:link {
    color: black;
}

.nav-dropdown:hover a.dropdown-toggle:link {
    color: #89cff0;
}

/*.nav-dropdown:has(a.dropdown-item.active) a.dropdown-toggle {*/
    /*color: #89cff0;*/
/*}*/

.nav-dropdown-th {
    text-transform: uppercase;
    font-family: Prompt, sans-serif;
    font-size: 16px;
    margin-top: 0;
}

.nav-dropdown-th a.dropdown-toggle:link {
    color: black;
}

.nav-dropdown-th:hover a.dropdown-toggle:link {
    color: #89cff0;
}

/*.nav-dropdown-th:has(a.dropdown-item.active) a.dropdown-toggle {*/
    /*color: #89cff0;*/
/*}*/

.nav-dropdown .dropdown-item {
    padding-left: 15px !important;
    /*text-align: center !important;*/
}

@media only screen and (max-width: 768px) {
    .nav-dropdown .dropdown-item {
        padding-left: 0 !important;
        text-align: center !important;
    }
}

.nav-link {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.nav-link span.nav-link-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: #89cff0;
    transition: all 0.5s;
}

.nav-link:hover span.nav-link-line {
    left: 5%;
    width: 90%;
}

.nav-link.active span.nav-link-line {
    height: 2px;
    background: #89cff0;
    left: 5%;
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .nav-link:hover span.nav-link-line {
        left: 35%;
        width: 30%;
    }

    .nav-link.active span.nav-link-line {
        height: 2px;
        background: #89cff0;
        left: 35%;
        width: 30%;
    }
}

.nav-link-lang {
    text-align: right !important;
    padding-right: 10px !important;
}

span.lang-name {
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {
    .nav-link-lang {
        text-align: center !important;
    }
}

.nav-dropdown-th .lang-name {
    font-family: Prompt, sans-serif;
    font-size: 16px;
}

.nav-dropdown .lang-name {
    font-family: Lato, sans-serif;
    font-size: 13px;
}