.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.top-section {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.main-section {
    margin-top: 170px;
}

@media only screen and (max-width: 922px) {
    .main-section {
        margin-top: 115px;
    }
}

/*.main-section {*/
    /*margin-top: 135px;*/
/*}*/

/*@media only screen and (max-width: 720px) {*/
    /*.main-section {*/
        /*margin-top: 150px;*/
    /*}*/
/*}*/

/*@media only screen and (max-width: 600px) {*/
    /*.main-section {*/
        /*margin-top: 180px;*/
    /*}*/
/*}*/

/*@media only screen and (max-width: 476px) {*/
    /*.main-section {*/
        /*margin-top: 225px;*/
    /*}*/
/*}*/

/*@media only screen and (max-width: 320px) {*/
    /*.main-section {*/
        /*margin-top: 270px;*/
    /*}*/
/*}*/